// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
// @ts-check
import { CaptureConsole, ExtraErrorData, Offline } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'

import { SENTRY_DSN } from './constants'

Sentry.init({
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  enabled: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production',
  dsn: SENTRY_DSN,
  attachStacktrace: true,
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  integrations: [
    new BrowserTracing(),
    new ExtraErrorData(),
    new CaptureConsole({ levels: ['error'] }),
    new Offline(),
  ],
  ignoreErrors: [
    'Uncaught Error in snapshot listener: FirebaseError: [code=permission-denied]: Missing or insufficient permissions.',
    "Unable to access localStorage SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
    'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API',
    'Analytics.js',
    'analytics.js',
    'ResizeObserver loop limit exceeded',
    'NotSupportedError: screen.orientation.lock() is not available on this device.',
    'ResizeObserver loop completed with undelivered notifications.',
    'antiFingerPrint is not defined',
    '@firebase/app-check: FirebaseError: AppCheck: ReCAPTCHA error. (appCheck/recaptcha-error).',
    '[ACII]: {"description":"HTML Canvas - WebGL Context",',
    'AppCheck',
  ],
  allowUrls: [/https?:\/\/((cdn|app|demo)\.)?arcade\.software/],
  beforeSend: event => {
    const msg = event?.message || ''
    const ignoredMobileEvents =
      window.navigator.userAgent.toLowerCase().includes('mobile') &&
      (msg.includes('Operation is not supported') ||
        msg.includes('ScreenOrientation') ||
        msg.includes('screen.orientation') ||
        msg.includes('app-check') ||
        msg.includes('IDBDatabase') ||
        msg.includes('The document is sandboxed') ||
        msg.includes('Database deleted by request of the user') ||
        msg.includes('@firebase/firestore') ||
        msg.includes('Firebase: Error') ||
        msg.includes('createObjectStore') ||
        msg.includes('app/storage'))
    if (
      process.env.NODE_ENV !== 'production' ||
      event?.environment !== 'production' ||
      msg.includes('appCheck') ||
      msg.includes('XMLHttpRequestProgressEvent') ||
      msg.includes('Failed to load Analytics.js') ||
      msg.includes('[object ProgressEvent]') ||
      msg.includes('TypeError: Load failed') ||
      msg.includes('The operation is insecure') ||
      msg.includes('Resource blocked by content blocker') ||
      msg.includes('Error loading https://cdn.arcade.software/scripts/') ||
      window.navigator.userAgent.toLowerCase().includes('headless') ||
      ignoredMobileEvents
    ) {
      return null
    }
    return event
  },
})
