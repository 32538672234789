/* eslint-disable @typescript-eslint/no-var-requires */
const plugin = require('tailwindcss/plugin')

const arcadeColors = {
  white: '#FFFFFF',
  primary: {
    50: '#F1F3FE',
    100: '#D5DBFC',
    200: '#AFBCF9',
    300: '#8B9DF6',
    400: '#677EF2',
    500: '#4460ED',
    600: '#2142E7',
    700: '#182FA5',
    800: '#0E1C63',
    900: '#050920',
    // primary is our branded blue color
  },
  neutral: {
    50: '#F9FAFB',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
    // neutral is our dark-gray color that we use for backgrounds, texts and icons
  },
  success: {
    50: '#DBF5E6',
    100: '#B8E9CC',
    200: '#97DCB3',
    300: '#77CD9A',
    400: '#58BD81',
    500: '#3BAB68',
    600: '#1F9850',
    700: '#166C39',
    800: '#0D4122',
    900: '#04150B',
  },
  warning: {
    50: '#FFF9E6',
    100: '#FFEFC0',
    200: '#FFE699',
    300: '#FFDC73',
    400: '#FFD34D',
    500: '#FFC927',
    600: '#FFC001',
    700: '#997301',
    800: '#725600',
    900: '#2B2100',
  },
  error: {
    50: '#FFF2F3',
    100: '#FFCACF',
    200: '#FFA3AD',
    300: '#FF7E8C',
    400: '#FD5C6D',
    500: '#F63C50',
    600: '#EE1E34',
    700: '#AA1525',
    800: '#660D16',
    900: '#210407',
  },
  pink: {
    500: '#FFCDED',
  },
  purple: {
    700: '#7E22CE',
  },
}

module.exports = {
  mode: 'jit',
  content: ['./src/**/*.{js,ts,jsx,tsx}', './pages/**/*.{js,ts,jsx,tsx}'],
  darkMode: 'class',
  theme: {
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      'not-allowed': 'not-allowed',
      crosshair: 'crosshair',
      'zoom-in': 'zoom-in',
      'col-resize': 'col-resize',
    },
    extend: {
      colors: {
        ...arcadeColors,
        'primary-blur': arcadeColors.primary[500],

        'accent-content': arcadeColors.primary[600],

        'neutral-blur': arcadeColors.neutral[500],
        'base-50': arcadeColors.neutral[50],

        danger: arcadeColors.error[600],
        'danger-focus': arcadeColors.error[700],
        'danger-blur': arcadeColors.error[500],
        'danger-content': arcadeColors.white,
      },
      boxShadow: {
        btn: '0px 3px 3px rgba(17, 24, 39, 0.04), 0px 2px 2px -2px rgba(17, 24, 39, 0.06)',
        'l-ring':
          '0px 0px 0px 1px rgba(17, 24, 39, 0.12), 0px 2px 2px -2px rgba(17, 24, 39, 0.03), 0px 4px 4px rgba(17, 24, 39, 0.03), 0px 8px 8px rgba(17, 24, 39, 0.03), 0px 16px 16px rgba(17, 24, 39, 0.03)',
      },
      aspectRatio: {
        '3/2': '3 / 2',
      },
      fontFamily: {
        sans: '"Inter", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        browser:
          '"Helvetica Neue", Arial, Roboto, ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      },
      width: {
        90: '25rem',
      },
      transitionProperty: {
        width: 'width',
      },
      animation: {
        'slide-up': 'slide-up .5s ease-out 1 normal both',
        'slide-up-2s-delay': 'slide-up .5s ease-out 2s 1 normal both',
      },
      keyframes: {
        'slide-up': {
          from: {
            opacity: 0,
            transform: 'translateY(100%)',
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      },
      screens: {
        webflowlg: '992px',
        webflow2xl: '1400px',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    require('tailwindcss-empty-pseudo-class')(),
    require('tailwindcss-animatecss')({
      classes: [
        'animate__animated',
        'animate__infinite',
        'animate__heartBeat',
        'animate__headShake',
        'animate__shakeX',
        'animate__wobble',
        'animate__faster',
        'animate__fast',
      ],
      // variants: ['responsive', 'hover', 'reduced-motion'],
    }),
    require('daisyui'),

    plugin(function ({ addVariant, e, postcss, addUtilities }) {
      addVariant('firefox', ({ container, separator }) => {
        const isFirefoxRule = postcss.atRule({
          name: '-moz-document',
          params: 'url-prefix()',
        })
        isFirefoxRule.append(container.nodes)
        container.append(isFirefoxRule)
        isFirefoxRule.walkRules(rule => {
          rule.selector = `.${e(
            `firefox${separator}${rule.selector.slice(1)}`
          )}`
        })
      })
      addUtilities({
        '.no-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
        '.no-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      })
    }),
  ],
  daisyui: {
    themes: [
      {
        arcade: {
          primary: arcadeColors.primary[600],
          'primary-focus': arcadeColors.primary[700],
          '--primary-blur': arcadeColors.primary[500],
          'primary-content': arcadeColors.white,

          secondary: arcadeColors.white,
          'secondary-content': arcadeColors.neutral[900],
          // As we currently don't have a real secondary color, we use white and neutral

          accent: arcadeColors.primary[50],
          'accent-focus': arcadeColors.primary[100],
          'accent-content': arcadeColors.primary[600],
          // We don't have an accent color, we different shades of neutral

          neutral: arcadeColors.neutral[900],
          'neutral-focus': arcadeColors.neutral[900],
          '--neutral-blur': arcadeColors.neutral[500],
          'neutral-content': arcadeColors.white,

          '--base-50': arcadeColors.neutral[50],
          'base-100': arcadeColors.neutral[100],
          'base-200': arcadeColors.neutral[200],
          'base-300': arcadeColors.neutral[300],
          'base-content': arcadeColors.neutral[900],
          // base and neutral are used for backgrounds, texts and icons

          '--danger': arcadeColors.error[600],
          '--danger-focus': arcadeColors.error[700],
          '--danger-blur': arcadeColors.error[500],
          '--danger-content': arcadeColors.white,

          info: arcadeColors.primary[600],
          success: arcadeColors.success[600],
          warning: arcadeColors.warning[600],
          error: arcadeColors.error[600],

          '--rounded-box': '.5rem',
          '--rounded-btn': '.5rem',
          '--rounded-badge': '1.9rem',

          '--animation-btn': '0.25s',
          '--animation-input': '0.2s',

          '--btn-text-case': 'none',
          '--navbar-padding': '0.5rem',
          '--border-btn': '0',
        },
      },
    ],
  },
  arcadeColors: {
    ...arcadeColors,
    primary: {
      warning: arcadeColors.warning[600],
      success: arcadeColors.success[600],
      primary: arcadeColors.primary[600],
      hotspot: arcadeColors.purple[700],
      error: arcadeColors.error[600],
      pink: arcadeColors.pink[500],
      black: arcadeColors.neutral[900],
      white: arcadeColors.white,
    },
  },
}
